import DDLogs from '@/DDLogs';
import { clientWithoutErrorHandling, client } from '../http';

export default {
  async createConsent(userIdInBank, bankIdentifier, name, closedBankingCredentialsId) {
    const response = await client.post(
      '/api/aggregator/v2/consent',
      {
        userIdInBank,
        bankIdentifier,
        metaData: { name, closedBankingCredentialsId },
      },
    );
    return response.data;
  },

  async getConsents() {
    const response = await clientWithoutErrorHandling.get('/api/full-consent-by-customer-id');
    return response.data;
  },

  async updateConsent(bankIdentifier, consentId, credentialsId) {
    const response = await client.post(
      '/api/aggregator/consent/update-by-credentialsId',
      {
        bankIdentifier,
        consentId,
        metaData: { credentialsId },
      },
    );
    return response.data;
  },

  async getConsentInfo(credentialsId) {
    const response = await client.post('/api/aggregator/consent/info', { metaData: { credentialsId } });
    return response.data;
  },
  async getCredentialsConsent({ bankIdentifier, userIdInBank }) {
    const response = await client.get(`/api/aggregator/consent/credentials/${bankIdentifier}/${userIdInBank}`);
    return response.data;
  },
  async getMigratedCredentialsConsent(closedBankingCredentialsId) {
    const response = await client.get(`/api/aggregator/consent/migrated-credentials/${closedBankingCredentialsId}`);
    return response.data;
  },
  async getSourceConfigurations() {
    try {
      const response = await clientWithoutErrorHandling.get('/api/aggregator/source-configurations');
      return response.data;
    } catch (e) {
      DDLogs.error('Error happened with source configuration', e);
      return [];
    }
  },
  async applyAccessCode(consentId, bankIdentifier, accessCode) {
    const response = await clientWithoutErrorHandling.post('/api/aggregator/consent/apply-code', {
      consentId,
      accessCode,
      bankIdentifier,
    });
    return response.data;
  },
  async getAssetsAndLiabilities() {
    const response = await client.get('/api/aggregator/financial-summary');
    return response.data;
  },
};
