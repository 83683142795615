import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import cashflowViewConsts from '../../../constants/cashflow-view';
import ChangeTransactionCategorySettingsPopup
  // eslint-disable-next-line max-len
  from '../../../pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/ChangeTransactionCategorySettingsPopup.vue';

const ENRICHMENT_INPUT_TYPES = {
  TRANSACTION_ID: 'transactionId',
  BUSINESS_NAME: 'businessName',
  PAYMENT_APP_ADDRESSEE: 'paymentAppAddressee',
};

function normalizeTrackingCategories(allCategories) {
  return _.map(allCategories, category => {
    if (category.categoryName === cashflowViewConsts.CATEGORY_NAMES.TRACKING) {
      return { ...category, targetPopup: ChangeTransactionCategorySettingsPopup };
    }
    return category;
  });
}

function normalizeOneTimeSavingCategory(category) {
  if (!category) { return []; }
  return [{
    categoryId: category.categoryId,
    categoryName: cashflowViewConsts.CATEGORY_NAMES.ONE_TIME_SAVING,
    expense: category.categoryName,
    cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.ONE_TIME_SAVING,
    icon: 'arrow_circle_up',
    color: BaseUI.IconSquareColorVariants.ORANGE,
    label: 'חיסכון בתזרים (חד פעמי)',
    pluralLabel: category.categoryName,
    style: cashflowViewConsts.CATEGORY_STYLE[cashflowViewConsts.CASHFLOW_CATEGORIES.ONE_TIME_SAVING],
    isSaving: true,
  }];
}

function getTrackingCategories(allCategories, availableCashflowCategories) {
  if (availableCashflowCategories.includes(cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING)) {
    return _.filter(allCategories, category => category.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING);
  }
  return [];
}

function getNonRecurringCategories(categories, availableCashflowCategories) {
  const nonRecurringCashflowCategories = _.difference(
    availableCashflowCategories,
    [cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING],
  );
  return _.filter(categories, category => _.includes(nonRecurringCashflowCategories, category.cashflowCategory));
}

function orderCategories(categories) {
  const precedence = [
    cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE,
    cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING,
    cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED,
    cashflowViewConsts.CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE,
    cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE_INCOME,
    cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_INCOME,
    cashflowViewConsts.CASHFLOW_CATEGORIES.EXCLUDED_INCOME,
  ];
  return _.sortBy(categories, c => _.indexOf(precedence, c.cashflowCategory));
}

function mapPlanToExcludedExpensePlanCategory(excludedExpenseCategory, plan) {
  return {
    ...excludedExpenseCategory,
    categoryId: plan._id,
    label: plan.name,
    subLabel: 'תוכנית גדולה',
    icon: plan.unicodeIcon,
    color: BaseUI.IconSquareColorVariants.GREEN,
    planAheadCategory: true,
    plan,
  };
}

const state = {
  categoryRuleType: null,
  trackingCategories: null,
  transaction: null,
  monthsDiff: null,
  originalCategoryName: null,
  originalCategoryId: null,
  selectedCategoryName: null,
  selectedCategoryId: null,
  allCategories: null,
  oneTimeSavingCategory: null,
};

const getters = {
  ENRICHMENT_INPUT_TYPES: () => ENRICHMENT_INPUT_TYPES,
  allCategories: state => normalizeTrackingCategories(state.allCategories),
  allCategoriesWithHidden: (state, getters) => [...getters.allCategories, ...normalizeOneTimeSavingCategory(getters.oneTimeSavingCategory)],
  excludedExpenseCategory: (state, getters) => _.find(
    getters.allCategories,
    { cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE },
  ),
  planAheadCategories: (state, getters, rootState, rootGetters) => rootGetters['planAhead/plansForTransactionPlacement']
    .map(plan => mapPlanToExcludedExpensePlanCategory(getters.excludedExpenseCategory, plan)),
  allCategoriesWithHiddenAndPlanAhead: (state, getters) => [...getters.allCategoriesWithHidden, ...getters.planAheadCategories],
  oneTimeSavingCategory: state => state.oneTimeSavingCategory,
  excludedSavingCategory: (state, getters) => ({
    ...getters.excludedExpenseCategory,
    icon: 'arrow_circle_up',
    label: 'חיסכון לא בתזרים',
    isSaving: true,
  }),
  categoryToVariant: () => cashflowViewConsts.CATEGORY_STYLE,
  isIncomeCategory: state => _.includes(
    [
      cashflowViewConsts.CATEGORY_NAMES.VARIABLE_INCOME,
      cashflowViewConsts.CATEGORY_NAMES.FIXED_INCOME,
      cashflowViewConsts.CATEGORY_NAMES.EXCLUDED_INCOME,
    ],
    state.originalCategoryName,
  ),
  incomeOrExpense: (state, getters) => (getters.isIncomeCategory ? 'הכנסה' : 'הוצאה'),
  originalCategory: (state, getters) => {
    if (state.originalCategoryId) {
      return _.find(getters.allCategoriesWithHiddenAndPlanAhead, { categoryId: state.originalCategoryId });
    }
    return _.find(getters.allCategoriesWithHiddenAndPlanAhead, { categoryName: state.originalCategoryName });
  },
  selectedCategory: (state, getters) => {
    if (state.selectedCategoryId) {
      return _.find(getters.allCategoriesWithHiddenAndPlanAhead, { categoryId: state.selectedCategoryId });
    }
    return _.find(getters.allCategoriesWithHiddenAndPlanAhead, { categoryName: state.selectedCategoryName });
  },
  sameCategorySelected: state => {
    if (state.selectedCategoryId) {
      return state.selectedCategoryId === state.originalCategoryId;
    }
    return state.selectedCategoryName === state.originalCategoryName;
  },
  originalCashflowCategory: (state, getters) => getters.originalCategory.cashflowCategory,
  availableCashflowCategories: (state, getters) => cashflowViewConsts.AVAILABLE_CASHFLOW_CATEGORIES[getters.originalCashflowCategory],
  availableCategories: (state, getters, rootState) => {
    const nonRecurringCategories = getNonRecurringCategories(getters.allCategories, getters.availableCashflowCategories);
    const trackingCategories = getTrackingCategories(getters.allCategories, getters.availableCashflowCategories);
    const allowAssignToPlan = rootState.planAhead.eligibleForPlanAhead && !getters.isIncomeCategory
      && getters.originalCategory.cashflowCategory !== cashflowViewConsts.CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE;
    const planAheadCategories = allowAssignToPlan ? getters.planAheadCategories : [];

    return orderCategories([...planAheadCategories, ...nonRecurringCategories, ...trackingCategories]);
  },
  splitAvailableCategories(state, getters, rootState, rootGetters) {
    const { availableCategories } = getters;
    const availableCategoriesForSplit = _.chain(availableCategories)
      .reject(category => _.includes([
        cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED,
        cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_INCOME,
      ], category.cashflowCategory))
      .reject(category => ((getters.originalCategory.categoryName === cashflowViewConsts.CATEGORY_NAMES.ONE_TIME_SAVING)
        ? getters.planAheadCategories.includes(category)
        : false))
      .value();

    const savingCategories = [...normalizeOneTimeSavingCategory(getters.oneTimeSavingCategory), getters.excludedSavingCategory];

    return [
      ...availableCategoriesForSplit,
      ...savingCategories,
    ];
  },
  valueForPredicate: state => {
    if (state.categoryRuleType === ENRICHMENT_INPUT_TYPES.BUSINESS_NAME) {
      return state.transaction.businessName;
    }
    if (state.categoryRuleType === ENRICHMENT_INPUT_TYPES.PAYMENT_APP_ADDRESSEE) {
      return state.transaction.paymentAppAddressee;
    }
    if (state.categoryRuleType === ENRICHMENT_INPUT_TYPES.TRANSACTION_ID) {
      return state.transaction.transactionId;
    }
    return null;
  },
};

const actions = {
  startEditingTransaction({ commit, rootGetters, dispatch }, { transaction, categoryName, categoryId }) {
    dispatch('reset');
    commit('setTransaction', transaction);
    if (categoryName && !categoryId) {
      commit('setOriginalCategoryName', categoryName);
      commit('setSelectedCategoryName', categoryName);
    } else if (categoryId) {
      commit('setOriginalCategoryId', categoryId);
      commit('setSelectedCategoryId', categoryId);
    }
    commit('setTrackingCategories', rootGetters['cashflowView/trackingCategories']);
    commit('setAllCategories', rootGetters['cashflowView/allCategories']);
    commit('setOneTimeSavingCategory', rootGetters['cashflowView/oneTimeSavingCategory']);
  },
  reset({ commit }) {
    commit('setOriginalCategoryName', null);
    commit('setSelectedCategoryName', null);
    commit('setOriginalCategoryId', null);
    commit('setSelectedCategoryId', null);
  },
};

const mutations = {
  setSelectedCategoryName(state, selectedCategoryName) {
    state.selectedCategoryName = selectedCategoryName;
    state.selectedCategoryId = null;
  },
  setSelectedCategoryId(state, selectedCategoryId) {
    state.selectedCategoryId = selectedCategoryId;
    state.selectedCategoryName = null;
  },
  setTrackingCategories(state, trackingCategories) {
    state.trackingCategories = trackingCategories;
  },
  setAllCategories(state, allCategories) {
    state.allCategories = allCategories;
  },
  setOneTimeSavingCategory(saving, oneTimeSavingCategory) {
    state.oneTimeSavingCategory = oneTimeSavingCategory;
  },
  setTransaction(state, transaction) {
    state.transaction = transaction;
  },
  setCategoryRuleType(state, categoryRuleType) {
    state.categoryRuleType = categoryRuleType;
  },
  setOriginalCategoryName(state, originalCategoryName) {
    state.originalCategoryName = originalCategoryName;
  },
  setOriginalCategoryId(state, originalCategoryId) {
    state.originalCategoryId = originalCategoryId;
  },
  setMonthsDiff(state, monthsDiff) {
    state.monthsDiff = monthsDiff;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
