import { client } from '../http';

export default {
  async getSimulatorInsights() {
    const response = await client.post('/api/insight-simulator');
    return response.data;
  },
  async getHtmlLlmInsights() {
    const response = await client.get('/api/html-llm-insights');
    return response.data;
  },
};
