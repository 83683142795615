export default {
  INPUT_TYPES: {
    BUDGET_CATEGORY_INPUT: 'budgetCategoryInput',
    DELETED_INPUT: 'deletedInput',
    RESOLVED_BUG_INPUT: 'resolvedBugInput',
    PAPAS_MERGE_INPUT: 'papasMergeInput',
    SONS_MERGE_INPUT: 'sonsMergeInput',
    ACCOUNT_INPUT: 'accountInput',
    CANCEL_ENVELOPE_INPUT: 'cancelEnvelopeInput',
    ENRICHMENT_INPUT: 'enrichmentInput',
    CHANGE_ENVELOPE_AMOUNT_INPUT: 'changeEnvelopeAmountInput',
    RISEUP_GOAL_INPUT: 'riseupGoalInput',
    ENVELOPE_EXPENSE_CATEGORY_INPUT: 'envelopeExpenseCategoryInput',
  },
  FEATURE_FLAG_NAMES: {
    STORY_MENU: 'story-menu',
    TRACKING_CATEGORY_CHALLENGE: 'tracking-category-challenge',
    POSITIVE_CHALLENGE_NEW_COPY: 'positive-challenge-new-copy',
    JOIN_AMBASSADORS_INSIGHT: 'elsa-join-ambassadors-insight',
    JOIN_AMBASSADORS_LINK: 'elsa-join-ambassadors-link',
    SAVE_STORY_SHARE: 'save-story-share',
    FIXED_INCOME_USING_ML_MODEL: 'fixed-income-using-ml-model',
    SHARE_PREVIEW_IMAGES: 'share-preview-image',
    INTERCOM_AB_TEST: 'intercom_ab_test',
    WINE_AND_DINE_SEGMENT_TEST: 'wine-and-dine-segment-test',
    MONEY_BACK_GUARANTEE_TEST: 'money-back-guarantee-test',
    MIGRATION_FROM_PASSWORDS: 'migration-from-passwords',
    SHOW_EDIT_TRACKING_CATEGORY_NAME: 'show_edit_tracking_category_name',
    BALANCE_MONEY_INSIGHT_IN_TRIAL: 'balance-money-insight-trial',
    BALANCE_MONEY_CALCULATOR: 'balance-money-calculator',
    AKIVA_STYLE: 'akiva-style',
    ENABLE_CHANGE_BUDGET_START_DAY: 'enable-change-budget-start-day',
    HEVER_BENEFIT_INSIGHT: 'elsa-segment-special-insight',
    JIT_TECHNICAL_ERROR: 'jit-technical-error',
    JIT_NEGATIVE_BALANCE_POSITIVE_CF: 'jit-negative-balance-positive-cf',
    JIT_SUBSCRIPTION_POPUP: 'jit-subscription-popup',
    JIT_UPDATE_CREDENTIALS: 'jit-update-credentials',
    JIT_LOADING_CREDENTIALS: 'jit-loading-credentials',
    JIT_BANK_CC_BUG: 'jit-bank-cc-bug',
    JIT_INVITE_MEMBER: 'jit-invite-member',
    JIT_MERGE_INVESTIGATION: 'jit-merge-investigation',
    JIT_PAPAS_MERGE: 'jit-papas-merge',
    JIT_HIGH_INCOME_EXCLUSION: 'jit-high-income-exclusion',
    JIT_CHURN: 'jit-churn',
    JIT_CONSENT_FAILURE_RETRY: 'jit-consent-failure-retry',
    JIT_CONSENT_FAILURE: 'jit-consent-failure',
    EXCLUDED_SAVINGS_TRANSACTION: 'excluded-savings-transaction',
    CASHFLOW_TABS: 'cashflow-tabs',
    PARTIAL_SCRAPE_ENABLED: 'enable_partial_scrape',
    MBG_ENABLE_YEARLY_SUBSCRIPTION: 'mbg-enable-yearly-subscription',
    ANNUAL_SUBSCRIPTION_DORMANTS: 'annual-subscription-dormants',
    PLAN_AHEAD_INTRODUCTION_POPUP: 'plan-ahead-introduction-popup',
    PLAN_AHEAD_EXISTING_FUND: 'plan-ahead-existing-fund',
    FINANCIAL_WAR_SECTION: 'financial-war-section',
    AMBASSADORS_MENU_BANNER: 'ambassadors-menu-banner',
    WHATSAPP_ALERTS_SETTINGS: 'whatsapp-alerts-settings',
    ADVISORY_INSIGHT_NEW_VARIANT: 'advisory-insight-v5-variant',
    INTERCOM_TEMPLATE_MODEL_ROLLOUT: 'template_model_rollout',
    CASHFLOW_SEARCH: 'cashflow-search',
    SHOW_MISLAKA_CONSENT_SCREEN: 'show-mislaka-consent-screen',
    DISABLE_ELSA_ANIMATIONS: 'disable-elsa-animations',
    NEW_SHORT_TERM_DORMANT: 'new-short-term-dormant',
    EOY_2023_STORY: 'story-ff-eoy2023',
    ADVISORY_STORY: 'story-ff-advisory-v1',
    CONTEST_STORY: 'story-ff-contest_story_2',
    NO_TRIAL_DISCOUNT: 'no-trial-discount',
    NEW_SUBSCRIPTION_CTA: 'new-subscription-cta',
    SIGNUP_OFFERINGS: 'create-signup-offerings-status',
    SHOW_MONEY_MOONV2_STORY_IN_CASHFLOW: 'moneymoonv2-cashflow',
    CHANGE_CATEGORY_RADIO_LIST: 'change-category-radio-list',
    SHOW_TRANSACTION_ORIGINAL_AMOUNT: 'show-transaction-original-amount',
    ADVISORY_SINGLE_MENU_BANNER: 'advisory-single-menu-banner',
    MY_PROGRESS_MENU_BANNER: 'my-progress-single-menu-banner',
    ENABLE_MOVE_TRANSACTIONS_BY_PAYMENT_APP_ADDRESSEE: 'enable-move-transactions-by-payment-app-addressee',
    SHOW_SUGGEST_OFFERINGS_POPUP: 'show-suggest-offerings-popup',
    INCOGNITO_MODE_DETECTION: 'incognito-mode-detection',
    INCOGNITO_MODE_DETECTION_AMPLITUDE_EVENTS: 'incognito-mode-detection-amplitude-events',
    OPEN_FOR_EMPLOYEES: 'open-for-employees',
    NEW_CRED_NAME_INPUT: 'elsa-new-cred-name-text',
    SHARE_TRANSACTION: 'elsa-enable-share-transaction',
    SHOW_INSIGHTS_CENTER_INSIGHT: 'show-insights-center-insight',
    PREVENT_POALIM_CLOSED_BANKING: 'prevent-poalim-closed-banking',
    OPEN_MEITAV_OBK: 'open-meitav-obk-connection',
    SHOW_LEUMI_CHROM_IPHONE_ALERT: 'show-leumi-iphone-chrome-alert',
    NEW_VOUCHERS_FLOW: 'payment-initiation-groceries',
  },
  FEATURE_FLAG_JSON_NAMES: {
    OFFERINGS_EXPERIMENT: 'offerings-experiment',
  },
  REMOTE_VARIABLES: {
    ISRAEL_BANK_INTEREST: 'israel-bank-interest',
    INFLATION_RATE: 'inflation-rate',
  },
};
