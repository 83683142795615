<template>
  <div>
    <colored-popup :class="getPersonalizedInsightPopupClass" :close-action="closeClicked" :dark-text="isDarkText">
      <template v-slot:top-content>
        <div class="title">
          <insight-builder-renderer :sections="popupTitle" :insight-data="insight.config.data"></insight-builder-renderer>
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="content">
          <insight-builder-renderer :sections="popupBody" :insight-data="insight.config.data" @link-clicked="linkClicked"></insight-builder-renderer>
          <riseup-button v-if="insight.config.popupCTA"
                         class="cta-button"
                         @click="linkClicked(replaceVariablesInText(insight.config.popupCTA.url))"
                         :size="'slim'"
                         :title="insight.config.popupCTA.text"
                         :variant="'primary'"/>
        </div>
        <div class="divider"></div>
        <div class="thumbs-prompt">מעניין אותך לראות עוד תובנות כאלה?</div>
        <div class="thumbs" v-if="!rated">
          <div class="thumbs-box" @click="thumbsUp">
            <div class="text">כן!</div>
            <img class="image" src="/images/drawings/coin-happy.png" alt="">
          </div>
          <div class="thumbs-box" @click="thumbsDown">
            <div class="text">לא</div>
            <img class="image" src="/images/drawings/coin-stressed.png" alt="">
          </div>
        </div>
        <div v-else class="thanks-for-answering">תודה! 🙏</div>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import InsightLib from '@riseupil/insight-lib';

export default {
  name: 'PersonalizedInsightPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    InsightBuilderRenderer: InsightLib.InsightBuilderRenderer,
  },
  props: {
    insight: { type: Object, required: true },
    insightConfig: { type: Object, required: true },
  },
  data() {
    return {
      rated: false,
    };
  },
  async created() {
    this.sendSegmentEvent('PersonalizedInsightPopupShown', this.insight);
  },
  computed: {
    topic() {
      return this.insight.topic;
    },
    popupTitle() {
      return this.insightConfig.popupTitle;
    },
    popupBody() {
      return this.insightConfig.popupBody;
    },
    isDarkText() {
      return this.insightConfig.popupTitleTextDark;
    },
    getPersonalizedInsightPopupClass() {
      const textColor = this.isDarkText
        ? 'personalized-insight-popup-dark' : 'personalized-insight-popup';
      const backgroundColor = this.insightConfig.popupTitleClass || 'primary';
      return `${textColor} ${backgroundColor}`;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('insights', ['markAsRead']),
    sendSegmentEvent(eventName, extraProps = {}) {
      Segment.trackUserInteraction(eventName, { ...this.insight, ...extraProps });
    },
    async closeClicked() {
      this.sendSegmentEvent('PersonalizedInsightPopupClosed');
      this.closeModal();
    },
    async close() {
      await this.markAsRead(this.insight);
      this.closeModal();
    },
    thumbsUp() {
      this.rated = true;
      this.sendSegmentEvent('PersonalizedInsightFeedbackClicked', { positive: true });
      setTimeout(() => this.close(), 1500);
    },
    thumbsDown() {
      this.rated = true;
      this.sendSegmentEvent('PersonalizedInsightFeedbackClicked', { positive: false });
      setTimeout(() => this.close(), 1500);
    },
    linkClicked(url) {
      Segment.trackUserInteraction('PersonalizedInsightLinkClicked', { ...this.insight });
      window.open(url, '_blank');
    },
    replaceVariablesInText(text) {
      return InsightLib.replaceVariablesInText(text, this.insight.config.data);
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.personalized-insight-popup {
  .title {
    font-size: 22px;
    font-weight: bold;
    text-align: right;
    color: $riseup_white;
  }
  .content {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 28px;
    text-align: right;
    .cta-button {
      margin: 20px 0;
    }
  }
  .thumbs-prompt {
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
    color: $riseup_blue;
    text-align: center;
  }
  .divider {
    height: 1px;
    width: 100%;
    background: $riseup_gray_20;
    margin: 0 0 0 0;
  }
  .thumbs {
    display: flex;
    gap: 20px;
    .thumbs-box {
      margin-top: 20px;
      flex-grow: 1;
      padding: 5px;
      display: flex;
      align-items: center;
      border-radius: 16px;
      border: solid 1px $riseup_gray_20;
      cursor: pointer;
      .image {
        width: 55%;
      }
      .text {
        width: 30%;
        font-weight: bold;
        margin-right: 14px;
      }
    }
  }

  .thanks-for-answering {
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  .bold {
    font-weight: bold;
  }
}

.personalized-insight-popup-dark {
  .title {
    font-size: 22px;
    font-weight: bold;
    text-align: right;
    color: $riseup_black;
  }
  .content {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 28px;
    text-align: right;
    .cta-button {
      margin: 20px 0;
    }
  }
  .thumbs-prompt {
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
    color: $riseup_blue;
    text-align: center;
  }
  .divider {
    height: 1px;
    width: 100%;
    background: $riseup_gray_20;
    margin: 0 0 0 0;
  }
  .thumbs {
    display: flex;
    gap: 20px;
    .thumbs-box {
      margin-top: 20px;
      flex-grow: 1;
      padding: 5px;
      display: flex;
      align-items: center;
      border-radius: 16px;
      border: solid 1px $riseup_gray_20;
      cursor: pointer;
      .image {
        width: 55%;
      }
      .text {
        width: 30%;
        font-weight: bold;
        margin-right: 14px;
      }
    }
  }

  .thanks-for-answering {
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  .bold {
    font-weight: bold;
  }
}
</style>
