const ONBOARDING_PAGE = {
  BOTI_INTRO: 'botiIntro',
  YAHAV_IS_BACK: 'yahavIsBack',
  PEPPER_CELEBRATION: 'pepperCelebration',
  PROGRESS_INDICATION_1: 'progressIndication1',
  PROGRESS_INDICATION_2: 'progressIndication2',
  PREPARING_CASHFLOW: 'preparingCashflow',
  CASHFLOW_INTRO_INFORMATION: 'cashflowIntroInformation',
  FORWARD_LOOKING_INFORMATION: 'forwardLookingInformation',
  OSH_BALANCE_INFORMATION: 'oshBalanceInformation',
  SECURITY_INFORMATION: 'securityInformation',
  PREPARE_CREDENTIALS: 'prepareCredentials',
  BANK_DETAILS: 'bankDetails',
  OBK_PARTIALLY_AUTHORIZED: 'partiallyAuthorised',
  INCOME_INVESTIGATION: 'incomeInvestigation',
  INCOME_EXCLUSION: 'highIncomeExclusion',
  CREDIT_CARD_DETAILS: 'creditCardDetails',
  EXPENSE_INVESTIGATION: 'expenseInvestigation',
  CASHFLOW: 'cashflow',
  PAST_MONTH: 'pastMonth',
  PLAN_AHEAD_PLANS_SELECTION: 'planAhead',

  // New pages for shorter ob experiment
  TIMELINE_INTRO: 'timelineIntro',
  SECURITY_INFORMATION_V2: 'securityInformationV2',
  PREPARE_CREDENTIALS_V2: 'prepareCredentialsV2',

  PREPARING_OTS_RESULTS: 'prepareOtsResults',
  OTS_RESULTS: 'otsResults',
};

export default {
  ONBOARDING_PAGE,
};
